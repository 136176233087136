<template>
  <div :class="{'pointer-events-none opacity-50': !selectedApprovalType.child}">
    <div class="no-scroll-content border border-solid d-theme-border-grey-light border-r-0 border-t border-b">
      <div class="flex border items-center">
        <vs-input v-model="search" @keyup.enter="getInitData" icon-no-border icon="icon-search" size="large" icon-pack="feather" placeholder="Search Maker" class="vs-input-no-border vs-input-no-shdow-focus w-full"/>
      </div>
      <div class="email__actions flex items-center flex-wrap justify-between p-2 border border-r-0 border-l-0 border-solid d-theme-border-grey-light">
        <div class="flex w-full justify-between items-center py-2">
          <div class="flex items-center px-2">
            <span v-if="loading" class="text-right text-xs">Loading...</span>
            <span v-else class="text-right text-xs font-semibold">Maker</span>
          </div>
          <div class="flex items-center space-x-3">
            <feather-icon @click="showModalAdd" icon="PlusIcon" class="cursor-pointer hover:text-primary" svg-classes="h-4 w-4"/>
            <feather-icon @click="refresh" icon="RefreshCwIcon" class="cursor-pointer hover:text-primary" svg-classes="h-4 w-4"/>
          </div>
        </div>
      </div>
      <component v-if="selectedApprovalType.child" :is="scrollbarTag" class="email-content-scroll-area" :settings="scrollbarSettings" ref="mailListPS">
        <div v-for="(item, index) in items" :key="index" @click.stop="onSelected(item)" class="flex px-3 py-3 space-x-2 w-full justify-between zs-center border border-solid border-t-0 border-b border-l-0 border-r-0 d-theme-border-grey-light" :class="{'bg-primary-transparent-10': selected === item}">
          <div class="flex items-center space-x-3">
            <feather-icon :icon="item.is_specific_user ? 'UserIcon' : 'ShieldIcon'" svg-classes="h-5 w-5"/>
            <div class="hover:font-medium">
              <p class="text-sm font-semibold">{{ item.is_specific_user ? item.nama_user_creator : item.nama_role_creator }}</p>
              <div class="flex items-center space-x-2">
                <span class="text-xs text-white" :class="'bg-' + (item.is_specific_user ? 'blue' : 'green')">{{ item.is_specific_user ? 'USER' : 'ROLE' }}</span>
                <p class="text-sm">{{ item.is_specific_user ? item.username_user_creator : item.kode_role_creator }}</p>
              </div>
            </div>
          </div>
          <div class="flex items-center space-x-2 pr-2">
            <feather-icon icon="EditIcon" svg-classes="h-3 w-3" class="hover:text-warning" @click="showModalEdit(item)"/>
            <feather-icon icon="TrashIcon" svg-classes="h-3 w-3" class="hover:text-danger" @click="confirmDelete(item)"/>
          </div>
        </div>
      </component>
    </div>

    <!--modals-->
    <MakerAdd :isActive.sync="modalAdd.active" :selectedApprovalType="modalAdd.selectedApprovalType" @success="refresh"/>
    <MakerEdit :isActive.sync="modalEdit.active" :selectedApprovalType="modalAdd.selectedApprovalType" :initData="modalEdit.initData" @success="refresh"/>
  </div>
</template>

<script>
import SystemApprovalRepository from '@/repositories/general/system-approvals-repository'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import _ from 'lodash'
import MakerAdd from '@/views/pages/general/system_approvals/maker/MakerAdd'
import MakerEdit from '@/views/pages/general/system_approvals/maker/MakerEdit'

export default {
  name: 'MakerSection',
  components: {
    MakerEdit,
    MakerAdd,
    VuePerfectScrollbar
  },
  computed: {
    scrollbarTag () {
      return this.$store.getters['theme/scrollbarTag']
    },
    scrollbarSettings () {
      return {
        maxScrollbarLength: 100,
        wheelSpeed: 0.8
      }
    },
    dataApprovalTypeSection () {
      return this.$store.state.general.systemApprovals.approvalTypeSection
    },
    selectedApprovalType () {
      return this.dataApprovalTypeSection.selected
    }
  },
  watch: {
    selectedApprovalType (selected) {
      this.items = []
      this.selected = null
      if (selected.child) {
        this.getInitData()
      }
    },
    selected: {
      deep: true,
      handler (value) {
        const data = { selected: value }
        this.commitDataToStore(data)
      }
    }
  },
  data () {
    return {
      search: null,
      loading: false,
      selected: null,
      items: [],
      modalAdd: {
        selectedApprovalType: {},
        active: false
      },
      modalEdit: {
        initData: {},
        active: false
      }
    }
  },
  methods: {
    showModalAdd () {
      this.modalAdd.selectedApprovalType = this.selectedApprovalType
      this.modalAdd.active = true
    },

    showModalEdit (maker) {
      this.modalEdit.initData = maker
      this.modalEdit.active = true
    },

    getInitData () {
      this.loading = true

      const params = {
        id_proyek: this.selectedApprovalType.id_proyek,
        type: this.selectedApprovalType.parent.type,
        min_amount: this.selectedApprovalType.child.min_amount,
        max_amount: this.selectedApprovalType.child.max_amount,
        search: this.search
      }
      SystemApprovalRepository.getCreators(params)
        .then(response => {
          this.items = response.data.data
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.loading = false
        })
    },

    onSelected (item) {
      this.selected = item
    },

    refresh () {
      this.selected = null
      this.getInitData()
    },

    confirmDelete (item) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Konfirmasi',
        text: 'Yakin ingin hapus?',
        acceptText: 'Hapus',
        cancelText: 'Batal',
        accept: () => { this.delete(item) }
      })
    },

    delete (item) {
      const params = {
        id_proyek: this.selectedApprovalType.id_proyek,
        type: this.selectedApprovalType.parent.type,
        min_amount: this.selectedApprovalType.child.min_amount,
        max_amount: this.selectedApprovalType.child.max_amount,
        id_role_creator: item.id_role_creator,
        id_user_creator: item.id_user_creator
      }
      SystemApprovalRepository.deleteCreator(params)
        .then(response => {
          this.refresh()
          this.notifySuccess('Data berhasil terhapus')
          if (this.selected === item) {
            this.selected = null
          }
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
    },

    commitDataToStore: _.debounce(function (value) {
      const payload = _.cloneDeep(value)
      this.$store.commit('general/systemApprovals/SET_MAKER_SECTION', payload)
    })
  }
}
</script>

<style lang="scss">
@import "~@/assets/scss/vuexy/apps/email.scss";
</style>
