<template>
  <div id="email-app" class="flex relative overflow-hidden">
    <!----------- USERS ------------->
    <div class="w-full sm:w-4/12 d-theme-dark-bg">
      <ApprovalTypeSection/>
    </div>

    <!----------- ROLES ------------->
    <div class="w-full sm:w-4/12 d-theme-dark-bg">
      <MakerSection/>
    </div>

    <!----------- PERMISSIONS ------------->
    <div class="w-full sm:w-4/12 d-theme-dark-bg">
      <ApproverSection/>
    </div>
  </div>
</template>

<script>
import ApprovalTypeSection from '@/views/pages/general/system_approvals/approval-type/ApprovalTypeSection'
import MakerSection from '@/views/pages/general/system_approvals/maker/MakerSection'
import ApproverSection from '@/views/pages/general/system_approvals/approver/ApproverSection'
import moduleSystemApprovals from '@/store/modules/general/system-approvals/system-approvals.store'

export default {
  name: 'SystemApprovals',
  components: {
    ApprovalTypeSection,
    MakerSection,
    ApproverSection
  },
  beforeCreate () {
    this.$store.registerModule(['general', 'systemApprovals'], moduleSystemApprovals)
  },
  beforeDestroy () {
    this.$store.commit('general/systemApprovals/RESET_STATE')
    this.$store.unregisterModule(['general', 'systemApprovals'])
  }
}
</script>

<style lang="scss">
@import "~@/assets/scss/vuexy/apps/email.scss";
</style>
