import httpClient from '@/utilities/http/http-client'
import _ from 'lodash'

const endpoint = 'api/v1/general/systemApprovals'

export default {
  getApprovalTypes (params = null) {
    const paramsFiltered = _.omitBy(params, _.isNil)
    const queryParams = paramsFiltered ? `?${new URLSearchParams(paramsFiltered).toString()}` : ''
    return httpClient.get(`${endpoint}/approvalTypes${queryParams}`)
  },

  createRange (params) {
    return httpClient.post(`${endpoint}/ranges`, params)
  },

  updateRange (params) {
    return httpClient.patch(`${endpoint}/ranges`, params)
  },

  deleteRange (params) {
    return httpClient.post(`${endpoint}/ranges/delete`, params)
  },

  getCreators (params = null) {
    const paramsFiltered = _.omitBy(params, _.isNil)
    const queryParams = paramsFiltered ? `?${new URLSearchParams(paramsFiltered).toString()}` : ''
    return httpClient.get(`${endpoint}/creators${queryParams}`)
  },

  createCreator (params) {
    return httpClient.post(`${endpoint}/creators`, params)
  },

  updateCreator (params) {
    return httpClient.patch(`${endpoint}/creators`, params)
  },

  deleteCreator (params) {
    return httpClient.post(`${endpoint}/creators/delete`, params)
  },

  getApprovers (params = null) {
    const paramsFiltered = _.omitBy(params, _.isNil)
    const queryParams = paramsFiltered ? `?${new URLSearchParams(paramsFiltered).toString()}` : ''
    return httpClient.get(`${endpoint}/approvers${queryParams}`)
  },

  createApprover (params) {
    return httpClient.post(`${endpoint}/approvers`, params)
  },

  updateApprover (params) {
    return httpClient.patch(`${endpoint}/approvers`, params)
  },

  deleteApprover (params) {
    return httpClient.post(`${endpoint}/approvers/delete`, params)
  }
}
