<template>
  <div>
    <vs-popup :title="'Add Range Condition for Approval ' + approvalType.alias" :active="isActive" v-on:update:active="emitModalIsActive">
      <div>
        <div class="vx-row">
          <div class="vx-col w-full">
            <ValidationErrors :errors="errors"/>
          </div>
        </div>
        <div class="vx-row mb-5">
          <div class="vx-col sm:w-12/12 w-full">
            <label class="ml-1 text-sm">Condition *</label>
            <vs-select v-model="data.condition" class="w-full">
              <vs-select-item v-for="(item, index) in conditions" :key="index" :value="item" :text="item"/>
            </vs-select>
          </div>
        </div>
        <div class="vx-row mb-5" v-if="data.condition === 'WITH RANGE'">
          <div class="vx-col sm:w-6/12 w-full">
            <label class="ml-1 text-sm">Minimum Amount</label>
            <p class="ml-1 text-xs opacity-50">Kosongkan jika tanpa minimum amount.</p>
            <v-money class="w-full" v-model="data.min_amount"/>
          </div>
          <div class="vx-col sm:w-6/12 w-full">
            <label class="ml-1 text-sm">Maximum Amount (IDR)</label>
            <p class="ml-1 text-xs opacity-50">Kosongkan jika tanpa maximum amount.</p>
            <v-money class="w-full" v-model="data.max_amount"/>
          </div>
        </div>
        <div class="vx-row float-right mt-6">
          <div class="vx-col w-full">
            <vs-button class="mr-3" type="border" @click="emitModalIsActive(false)">Batal</vs-button>
            <vs-button :disabled="isLoading" @click="save">
              <span v-if="isLoading" class="animate-pulse">Menyimpan...</span>
              <span v-if="!isLoading">Simpan</span>
            </vs-button>
          </div>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import SystemApprovalRepository from '@/repositories/general/system-approvals-repository'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import VMoney from '@/views/components/v-money/VMoney'
import _ from 'lodash'

export default {
  name: 'RangeAdd',
  props: {
    isActive: { required: true, type: Boolean },
    idProyek: { required: false, type: Number },
    approvalType: { required: true, type: Object }
  },
  components: {
    ValidationErrors,
    VMoney
  },
  data () {
    return {
      isLoading: false,
      errors: null,
      data: {
        condition: this.approvalType.is_rangeable ? 'WITH RANGE' : 'WITHOUT RANGE',
        min_amount: null,
        max_amount: null
      }
    }
  },
  computed: {
    conditions () {
      return this.approvalType.is_rangeable ? ['WITH RANGE', 'WITHOUT RANGE'] : ['WITHOUT RANGE']
    }
  },
  watch: {
    approvalType (newVal, oldVal) {
      if (!_.isEqual(newVal, oldVal)) {
        this.resetData()
      }
    },
    'data.condition' (condition) {
      if (condition === 'WITHOUT RANGE') {
        this.data.min_amount = null
        this.data.max_amount = null
      }
    }
  },
  methods: {
    save () {
      this.errors = null
      this.isLoading = true

      const params = {
        id_proyek: this.idProyek,
        type: this.approvalType.type,
        min_amount: this.data.min_amount > 0 ? this.data.min_amount : null,
        max_amount: this.data.max_amount > 0 ? this.data.max_amount : null
      }
      SystemApprovalRepository.createRange(params)
        .then(response => {
          this.onSuccess()
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    onSuccess () {
      this.notifySuccess('Data berhasil disimpan.')
      this.resetData()
      this.emitIsSuccess(true)
      this.emitModalIsActive(false)
    },

    resetData () {
      Object.assign(this.$data, this.$options.data.call(this))
    },

    emitIsSuccess (isSuccess) {
      this.$emit('success', isSuccess)
    },

    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
