<template>
  <div>
    <vs-popup title="Add Approver" :active="isActive" v-on:update:active="emitModalIsActive">
      <div>
        <div class="vx-row">
          <div class="vx-col w-full">
            <ValidationErrors :errors="errors"/>
          </div>
        </div>
        <div class="vx-row mb-5">
          <div class="vx-col sm:w-12/12 w-full">
            <label class="ml-1 text-sm">Step of Approval *</label>
            <vs-input class="w-full" v-model="data.no_urut" placeholder="Step" type="number"/>
          </div>
        </div>
        <div class="vx-row mb-5">
          <div class="vx-col sm:w-12/12 w-full">
            <label class="ml-1 text-sm">Type of Approver *</label>
            <vs-select v-model="data.approver_type" class="w-full">
              <vs-select-item v-for="(item, index) in ['ROLE', 'SPECIFIC USER']" :key="index" :value="item" :text="item"/>
            </vs-select>
          </div>
        </div>
        <div class="vx-row mb-5" v-if="data.approver_type === 'ROLE'">
          <div class="vx-col sm:w-12/12 w-full">
            <label class="ml-1 text-sm">Choose Role *</label>
            <vs-select autocomplete v-model="data.id_role_approver" class="w-full">
              <vs-select-item v-for="(it, index) in roles" :key="index" :value="it.id" :text="it.name"/>
            </vs-select>
          </div>
        </div>
        <div v-else class="vx-row mb-5">
          <div class="vx-col sm:w-12/12 w-full">
            <label class="ml-1 text-sm">Choose User *</label>
            <div @click="modalUser.active = true">
              <vx-input-group>
                <vs-input placeholder="Pilih User" :value="data.nama_user_approver" readonly/>
                <template slot="append">
                  <div class="append-text btn-addon">
                    <vs-button type="filled" icon-pack="feather" icon="icon-search"/>
                  </div>
                </template>
              </vx-input-group>
            </div>
          </div>
        </div>
        <div class="vx-row float-right mt-6">
          <div class="vx-col w-full">
            <vs-button class="mr-3" type="border" @click="emitModalIsActive(false)">Batal</vs-button>
            <vs-button :disabled="isLoading" @click="save">
              <span v-if="isLoading" class="animate-pulse">Menyimpan...</span>
              <span v-if="!isLoading">Simpan</span>
            </vs-button>
          </div>
        </div>
      </div>

      <!--modal user-->
      <vs-popup class="sm:popup-w-70 popup-content-no-padding"
                title="Pilih Data Staf"
                :active.sync="modalUser.active">
        <Staf v-if="!isFirstActive" :selectable="true" :externalFilter="filterStaf" @selected="onSelectedModalUser"/>
      </vs-popup>

    </vs-popup>
  </div>
</template>

<script>
import SystemApprovalRepository from '@/repositories/general/system-approvals-repository'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import _ from 'lodash'
import StafRepository from '@/repositories/master/staf-repository'
import Staf from '@/views/pages/master/staf/Staf'

export default {
  name: 'ApproverAdd',
  props: {
    isActive: { required: true, type: Boolean },
    selectedMaker: { required: true, type: Object },
    defaultNoUrut: { required: false, type: Number }
  },
  components: {
    ValidationErrors,
    Staf
  },
  computed: {
    filterStaf () {
      return { only_have_user: true }
    },
    dataApprovalTypeSection () {
      return this.$store.state.general.systemApprovals.approvalTypeSection
    }
  },
  data () {
    return {
      isFirstActive: true,
      isLoading: false,
      errors: null,
      roles: [],
      modalUser: {
        active: false
      },
      data: {
        approver_type: 'ROLE',
        id_role_approver: null,
        id_user_approver: null,
        nama_user_approver: null,
        no_urut: null
      }
    }
  },
  watch: {
    isActive (active) {
      if (active) {
        if (this.isFirstActive) {
          this.getInitData()
          this.isFirstActive = false
        }

        // set default
        this.data.no_urut = this.defaultNoUrut
      }
    },

    'data.approver_type' (approverType) {
      if (approverType === 'ROLE') {
        this.data.id_user_approver = null
        this.data.nama_user_approver = null
      } else {
        this.data.id_role_approver = null
      }
    }
  },
  methods: {
    onSelectedModalUser (data) {
      this.data.id_user_approver = data.id_user
      this.data.nama_user_approver = data.nama
      this.modalUser.active = false
    },

    getInitData () {
      this.getRoles()
    },

    getRoles () {
      StafRepository.getAllRole()
        .then(response => {
          this.roles = response.data.data
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
    },

    save () {
      this.errors = null
      this.isLoading = true

      const params = {
        id_proyek: this.dataApprovalTypeSection.selected.id_proyek,
        type: this.selectedMaker.type,
        min_amount: this.selectedMaker.min_amount,
        max_amount: this.selectedMaker.max_amount,
        id_role_creator: this.selectedMaker.id_role_creator,
        id_user_creator: this.selectedMaker.id_user_creator,
        id_role_approver: this.data.id_role_approver,
        id_user_approver: this.data.id_user_approver,
        no_urut: this.data.no_urut
      }
      SystemApprovalRepository.createApprover(params)
        .then(response => {
          this.onSuccess()
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    onSuccess () {
      this.notifySuccess('Data berhasil disimpan.')
      this.resetData()
      this.emitIsSuccess(true)
      this.emitModalIsActive(false)
    },

    resetData () {
      const exceptObjects = _.pick(this.$data, ['roles'])
      const newData = Object.assign(this.$options.data.call(this), exceptObjects)
      Object.assign(this.$data, newData)
    },

    emitIsSuccess (isSuccess) {
      this.$emit('success', isSuccess)
    },

    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
