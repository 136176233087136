<template>
  <div>
    <div class="no-scroll-content border border-solid d-theme-border-grey-light border-r-0 border-t border-b">
      <div class="flex border items-center">
        <vs-input v-model="search" @keyup.enter="getInitData" icon-no-border icon="icon-search" size="large" icon-pack="feather" placeholder="Search Approval Type" class="vs-input-no-border vs-input-no-shdow-focus w-full"/>
      </div>
      <div class="email__actions flex items-center flex-wrap justify-between p-2 border border-r-0 border-l-0 border-solid d-theme-border-grey-light">
        <div class="flex w-full justify-between items-center">
          <div class="flex items-center px-2">
            <span v-if="loading" class="text-right text-xs">Loading...</span>
            <span v-else class="text-right text-xs font-semibold">Approval Type</span>
          </div>
          <div class="flex items-center space-x-2">
            <vs-select autocomplete v-model="selected.id_proyek" @input="onProyekChanged" class="w-full vs-select-small" style="margin-top: -2.5px; margin-bottom: -2.5px; border: solid #414561 1px;">
              <vs-select-item class="vs-select-small" :value="null" text="Semua Proyek"/>
              <vs-select-item class="vs-select-small" v-for="(it, index) in proyeks" :key="index" :value="it.id" :text="it.kode + ' - ' + it.nama"/>
            </vs-select>
            <feather-icon @click="refresh" icon="RefreshCwIcon" class="cursor-pointer px-3 hover:text-primary" svg-classes="h-4 w-4"/>
          </div>
        </div>
      </div>
      <component :is="scrollbarTag" class="email-content-scroll-area" :settings="scrollbarSettings" ref="mailListPS">
        <div v-for="(parent, index) in items" :key="index" @click="parent.expanded = !parent.expanded">
          <div class="cursor-pointer border border-solid border-t-0 border-b border-l-0 border-r-0 d-theme-border-grey-light sm:px-4 px-2 py-2 hover-bg-primary-transparent-10" :class="{'bg-primary-transparent-10': selected.parent === parent}">
            <div class="flex w-full justify-between items-center">
              <div class="">
                <div class="flex items-center space-x-2">
                  <h6 class="font-semibold text-sm">{{ parent.alias }}</h6>
                  <span class="text-xxs opacity-75">({{ parent.type }})</span>
                </div>
                <span class="text-xs font-medium">{{ parent.children.length }} Conditions</span>
              </div>
              <div class="flex items-center space-x-3">
                <feather-icon v-if="parent.expanded" @click.stop="showModalAdd(parent)" icon="PlusIcon" svg-classes="h-4 w-4" class="hover:text-success"/>
                <feather-icon :icon="parent.expanded  ? 'ChevronUpIcon' : 'ChevronDownIcon'" svg-classes="h-4 w-4"/>
              </div>
            </div>
          </div>
          <!--expanded area-->
          <div v-if="parent.expanded" class="w-full cursor-pointer border border-solid border-t-0 border-b-0 border-l-0 border-r-0 d-theme-border-grey-light">
            <div v-if="parent.children.length < 1" class="w-full border border-solid border-t-0 border-b border-l-0 border-r-0 d-theme-border-grey-light">
              <p class="text-xs text-center w-full p-3">No data.</p>
            </div>
            <div v-else v-for="(child, index) in parent.children" :key="index" @click.stop="onChildSelected(parent, child)" class="flex px-3 py-3 space-x-2 w-full justify-between items-center border border-solid border-t-0 border-b border-l-0 border-r-0 d-theme-border-grey-light" :class="{'bg-primary-transparent-10': selected.child === child}">
              <div class="pl-4 hover:font-medium">
                <div v-if="child.is_with_range" class="flex items-center space-x-2">
                  <p class="text-xs">{{ child.min_amount === null ? 'No Limit' : idr(child.min_amount) }}</p>
                  <feather-icon icon="MinusIcon" svg-classes="h-4 w-4"/>
                  <p class="text-xs">{{ child.max_amount === null ? 'No Limit' : idr(child.max_amount) }}</p>
                </div>
                <div v-else class="flex items-center">
                  <p class="text-xs">Without Range</p>
                </div>
              </div>
              <div class="flex items-center space-x-2 pr-2">
                <feather-icon icon="EditIcon" svg-classes="h-3 w-3" class="hover:text-warning" @click="showModalEdit(parent, child)"/>
                <feather-icon icon="TrashIcon" svg-classes="h-3 w-3" class="hover:text-danger" @click="confirmDelete(parent, child)"/>
              </div>
            </div>
          </div>
        </div>
      </component>
    </div>

    <!--modals-->
    <RangeAdd :isActive.sync="modalAdd.active" :idProyek="selected.id_proyek" :approvalType="modalAdd.approvalType" @success="refresh"/>
    <RangeEdit :isActive.sync="modalEdit.active" :idProyek="selected.id_proyek" :approvalType="modalEdit.approvalType" :initData="modalEdit.initData" @success="refresh"/>
  </div>
</template>

<script>
import SystemApprovalRepository from '@/repositories/general/system-approvals-repository'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { v4 as uuid } from 'uuid'
import _ from 'lodash'
import RangeAdd from '@/views/pages/general/system_approvals/approval-type/RangeAdd'
import RangeEdit from '@/views/pages/general/system_approvals/approval-type/RangeEdit'
import ProyekRepository from '@/repositories/master/proyek-repository'

export default {
  name: 'ApprovalTypeSection',
  components: {
    RangeEdit,
    RangeAdd,
    VuePerfectScrollbar
  },
  mounted () {
    this.getProyek()
    this.getInitData()
  },
  computed: {
    scrollbarTag () {
      return this.$store.getters['theme/scrollbarTag']
    },
    scrollbarSettings () {
      return {
        maxScrollbarLength: 100,
        wheelSpeed: 0.8
      }
    }
  },
  watch: {
    selected: {
      deep: true,
      handler (value) {
        const data = { selected: value }
        this.commitDataToStore(data)
      }
    }
  },
  data () {
    return {
      search: null,
      loading: false,
      proyeks: [],
      selected: {
        id_proyek: null,
        parent: null,
        child: null
      },
      items: [],
      modalAdd: {
        approvalType: {},
        active: false
      },
      modalEdit: {
        approvalType: {},
        initData: {},
        active: false
      }
    }
  },
  methods: {
    showModalAdd (approvalType) {
      this.modalAdd.approvalType = approvalType
      this.modalAdd.active = true
    },

    showModalEdit (approvalType, initData) {
      this.modalEdit.approvalType = approvalType
      this.modalEdit.initData = initData
      this.modalEdit.active = true
    },

    onProyekChanged () {
      this.refresh()
    },

    getInitData () {
      this.loading = true

      const params = { search: this.search, id_proyek: this.selected.id_proyek }
      SystemApprovalRepository.getApprovalTypes(params)
        .then(response => {
          const typesAlreadyExpanded = _.map(_.filter(this.items, item => item.expanded), it => it.type)
          this.items = _.map(response.data.data, parent => {
            parent.uuid = uuid()
            parent.temporary = false
            parent.expanded = _.includes(typesAlreadyExpanded, parent.type)
            parent.children = _.map(parent.children, child => {
              child.uuid = uuid()
              return child
            })
            return parent
          })
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.loading = false
        })
    },

    getProyek () {
      ProyekRepository.getAll()
        .then(response => {
          this.proyeks = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    onChildSelected (parent, child) {
      this.selected.parent = parent
      this.selected.child = child
    },

    refresh () {
      this.selected.parent = null
      this.selected.child = null
      this.getInitData()
    },

    confirmDelete (parent, child) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Konfirmasi',
        text: 'Yakin ingin hapus?',
        acceptText: 'Hapus',
        cancelText: 'Batal',
        accept: () => { this.delete(parent, child) }
      })
    },

    delete (parent, child) {
      const params = {
        id_proyek: this.selected.id_proyek,
        type: parent.type,
        min_amount: child.min_amount,
        max_amount: child.max_amount
      }
      SystemApprovalRepository.deleteRange(params)
        .then(response => {
          this.refresh()
          this.notifySuccess('Data berhasil terhapus')
          if (this.selected.parent === parent && this.selected.child === child) {
            this.selected.parent = null
            this.selected.child = null
          }
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
    },

    idr (value, decimal = 2) {
      return this.$options.filters.idr(value, decimal)
    },

    commitDataToStore: _.debounce(function (value) {
      const payload = _.cloneDeep(value)
      this.$store.commit('general/systemApprovals/SET_APPROVAL_TYPE_SECTION', payload)
    })
  }
}
</script>

<style lang="scss">
@import "~@/assets/scss/vuexy/apps/email.scss";
</style>
